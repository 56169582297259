import { get } from 'lodash';
import { defineStore } from 'pinia';
import useHttp from '../src/composables/use-http';
import { useAccountStore, useAccountCreatorStore, useFeatureFlagsStore } from './';

const useCreatorStore = defineStore('creator', {
    state: () => {
        return {
            data: {
                liveUrls: null,
                networks: null,
                creator: null,
                agent: {
                    id: null,
                    email: get(window.SERVER_DATA, 'agent.email', null),
                    isPaymentAgent: get(window.SERVER_DATA, 'agent.is_payment_agent', false),
                    paymentSetupStatus: 'not_started'
                }
            },
            loadingCreator: false,
            loadingLiveUrls: false,
            loadingNetworks: false,
            loadingAgent: false,
            error: null
        };
    },
    actions: {
        async getCreator() {
            const { http } = useHttp();
            this.loadingCreator = true;
            this.error = null;
            try {
                const response = await http.get(`/creator/get`);
                this.data.creator = response.data;
            } catch (e) {
                this.data.creator = null;
                this.error = 'Sorry, there was a problem retrieving the current creator information.';
            } finally {
                this.loadingCreator = false;
            }
        },

        async getAllLiveUrls() {
            if (!useFeatureFlagsStore()?.data['live-site-switcher'] || !useAccountCreatorStore().data?.id) {
                return;
            }

            const { http } = useHttp();
            this.loadingLiveUrls = true;
            this.error = null;

            try {
                const response = await http.get(`/creator/live-urls`);
                this.data.liveUrls = response.data;

                if (!this.getCurrentLiveUrlAccount) {
                    this.data.liveUrls = null;
                }
            } catch (e) {
                this.data.liveUrls = null;
                this.error = 'Sorry, there was a problem retrieving your other live sites.';
            }

            this.loadingLiveUrls = false;
        },

        async getAllSocialNetworks() {
            if (!useAccountCreatorStore().data?.id) {
                return;
            }

            const { http } = useHttp();
            this.loadingNetworks = true;
            this.error = null;

            try {
                const response = await http.get(`/creator/social-networks`);
                this.data.networks = response.data;
            } catch (e) {
                this.data.networks = null;
                this.error = 'Sorry, there was a problem retrieving your social networks.';
            }

            this.loadingNetworks = false;
        },

        async linkAgent({ email }) {
            const { http } = useHttp();
            this.loadingAgent = true;
            this.error = null;

            try {
                await http.post(`/creator/agent/link`, { email });
                await this.getAgent();
            } catch (e) {
                if (e.status === 422) {
                    this.error = e.body.message;
                } else {
                    this.error = e.message ?? 'Sorry, there was a problem linking your agent.';
                }
            } finally {
                this.loadingAgent = false;
            }
        },
        async getAgent() {
            const { http } = useHttp();
            this.loadingAgent = true;
            this.error = null;

            try {
                const res = await http.get(`/creator/agents`);
                const data = res?.data;
                const paymentAgent = !!data?.is_payment_agent ?? false;
                this.data.agent = {
                    id: data?.id,
                    email: data?.email,
                    isPaymentAgent: paymentAgent
                };
                if (paymentAgent) {
                    const res = await http.get(`/creator/agent/payment-status?id=${this.data.agent.id}`);
                    this.data.agent = {
                        ...this.data.agent,
                        paymentSetupStatus: res.data
                    };
                }
            } catch (e) {
                this.data.agent = {
                    email: null,
                    isPaymentAgent: false
                };
                this.error = 'Sorry, there was a problem retrieving your agent.';
            } finally {
                this.loadingAgent = false;
            }
        },
        async setPaymentAgent({ email }) {
            const { http } = useHttp();
            this.loadingAgent = true;
            this.error = null;

            try {
                await http.post(`/creator/agent/payment`, { email });
                await this.getAgent();
            } catch (e) {
                if (e.status === 422) {
                    this.error = e.body.message;
                } else {
                    this.error = e.message ?? 'Sorry, there was a problem setting your payment agent.';
                }
            } finally {
                this.loadingAgent = false;
            }
        }
    },
    getters: {
        isLoading(state) {
            return state.loadingLiveUrls || state.loadingNetworks || state.loadingCreator;
        },
        isCurrentLiveUrlAccount(state) {
            return (liveUrlAccount) => {
                return state.getCurrentLiveUrlAccount?.brands[0]?.account_id === liveUrlAccount?.brands[0]?.account_id;
            };
        },
        getCurrentLiveUrlAccount(state) {
            return (
                state.data.liveUrls?.find(
                    (liveUrlAccount) =>
                        liveUrlAccount.brands.find((brand) => brand.account_id === useAccountStore().data?.id) || null
                ) || null
            );
        },
        getSocialNetworkById(state) {
            return (id) => state.data.networks?.find((network) => network.id === id) || null;
        },
        getFirstSocialNetwork(state) {
            return (networkId) => state.data.networks?.find((network) => network.network_id === networkId) || null;
        },
        getFirstActiveSocialNetwork(state) {
            return (networkId) =>
                state.data.networks?.find(
                    (network) => network.network_id === networkId && network.access_token_is_active === true
                ) || null;
        },
        getSocialNetwork(state) {
            return (networkId, accountId) => {
                let currentSocialNetwork = null;

                state.data.networks?.forEach((socialNetwork) => {
                    if (socialNetwork.network_id === networkId) {
                        socialNetwork.creator_social_networks.forEach((creatorSocialNetwork) => {
                            if (creatorSocialNetwork.account_id === accountId) {
                                currentSocialNetwork = socialNetwork;
                            }
                        });
                    }
                });

                return currentSocialNetwork;
            };
        },
        getCurrentLiveUrlSocialNetwork() {
            return (networkId) => {
                const currentLiveUrl = this.getCurrentLiveUrlAccount;
                if (!currentLiveUrl) {
                    return null;
                }

                return this.getSocialNetwork(networkId, currentLiveUrl.brands[0].account_id);
            };
        },
        getLiveUrlsMissingSocialNetwork(state) {
            return (networkId, externalId) => {
                const notConnected = [];
                state.data.liveUrls?.forEach((liveUrl) => {
                    if (this.canShareToBrand(liveUrl, networkId, externalId)) {
                        notConnected.push(liveUrl);
                    }
                });

                return notConnected;
            };
        },
        canShareToBrand() {
            return (liveUrl, networkId, externalId, excludeCurrentLiveUrl = true) => {
                const socialNetwork = this.getSocialNetwork(networkId, liveUrl.brands[0].account_id);

                if (excludeCurrentLiveUrl && this.isCurrentLiveUrlAccount(liveUrl)) {
                    return false;
                }

                if (socialNetwork) {
                    if (socialNetwork.access_token_is_active) {
                        return false;
                    } else if (socialNetwork.external_id !== externalId) {
                        return false;
                    }
                }

                return true;
            };
        },
        worksWithMulitpleBrands(state) {
            return state.data.liveUrls?.length > 1;
        }
    }
});

export default useCreatorStore;
